import React from 'react';
import './style.scss';
import Line from '../images/line.png';


const Midsection = () => (
	<div>
		<div className="container">
			<img className="line" src={Line}></img>
		</div>
		<section className="section">
			<div className="container">
				<div className="columns is-multiline">
					<div className="column is-one-third">
						<h3>
							About the tour:
						</h3>
						<p>
							You know the stories of knights, sieges and spectacular battles, don’t you? The Castle in Malbork is an icon of Medieval Poland and the largest brick castle in the world. You will feel like a true explorer. Take an unforgettable one-day trip with us to the Gdansk environs! The Malbork Castle was inscribed on the UNESCO World Heritage List. Do not miss the chance for an unforgettable adventure. At the end, we will invite you to a traditional Polish lunch with a local craft beer.
						</p>
					</div>
					<div className="column is-one-third">
						<h3>Route:</h3>
						<p>
							We start a tour in any location in Gdansk, Sopot or Gdynia. We will pick you ap from your hotel or apartment. With a minivan we will start exploring Zulawy Wislane with a few stops to find a wooden old arcaded front houses and Mennonite cementary. The main part of the trip is a tour of the Malbork Castle. At the end, we leave a beautiful landscape for you at the junction of two mighty rivers Vistula and Nogat. We will travel about 150 km in total with a break for a lunch.</p>
					</div>
					<div className="column is-one-third">
						<h3>About us:</h3>
						<p>
							Our guides are enthusiasts who know the castle like hardly anyone. We are a group of local guides and licensed professionals who love Gdansk and our region. We will be happy to show you the most interesting places of the Zulawy Wislane, as well as discover the secret side of Malbork Castle. Do not expect a boring trip, adventure awaits us around every corner.
							</p>
					</div>

				</div>
			</div>
		</section>
	</div>
);

export default Midsection;
